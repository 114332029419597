<template>
  <!-- <nav> -->
    <!-- <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link> -->
  <!-- </nav> -->
  <router-view/>


</template>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Archivo&family=Inter:wght@400;500&family=Khand:wght@300;400;500;600;700&family=Roboto:wght@500&display=swap');
@mixin transition($transition...) {
  -moz-transition:    $transition;
  -ms-transition:      $transition;
  -webkit-transition: $transition;
  transition:         $transition;
}
</style>
