<template>
    <footer class="container inter color-white">
        <p class="inter">© 2024 Eastern Star Entertainment.</p>
    </footer>
</template>

<style lang="scss">
footer {
    font-size: 16px;
    font-weight: 400;
    line-height: 28px; 
    padding-bottom: 40px !important;
    padding-top: 30px !important;
}

@media screen and (max-width: 600px) {
    footer {
        text-align: center;
        padding-top: 20px !important; 
        padding-bottom: 20px !important;
        font-size: 12px;
    }
}
</style>