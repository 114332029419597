<template>
    <div class="b-modal">
        <div class="b-modal-wrapper" @click="isClosed = true, $emit('update:modelValue', false), $emit('close', false)"></div>
        <div class="b-modal-content" :class="{'hide': isClosed && !modelValue, 'show': modelValue}">
            <div class="b-modal-content-div">
                <svg style="cursor: pointer;" @click="isClosed = true, $emit('update:modelValue', false), $emit('close', false)" class="b-modal-close" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M6 18L18 6M6 6L18 18" stroke="white" stroke-opacity="0.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <div class="b-modal-content__header">
                    <p class="uppercase color-white">{{ (!called && !isSend) || (called && !second) ? "Message us" : (!called && isSuccess) || (called && parentSuccess)  ? "Success!" : "Error!" }}</p>
                </div>
                <div class="" v-if="!isSend && !called">
                    <form class="b-modal-form d-flex column">
                        <div class="d-flex justify-center b-modal-form__first">
                            <UiInput placeholder="Name" v-model="data.name" :isError="isError && data.name.toString().trim().length == 0" :key="isError"/>
                            <UiInput placeholder="E-mail" v-model="data.email" inputType="email" :isError="isError && !emailRegex.test(data.email)" :key="isError"/>
                        </div>
                        <UiInput placeholder="Subject" v-model="data.subject" :key="isError" />
                        <UiInput placeholder="Message" v-model="data.message" isTextarea :key="isError"/>
                        <div class="b-modal-form__checkbox d-flex align-start justify-between">
                            <div class="d-flex column">
                                <label class="custom-checkbox d-flex" for="ch-1" @click="privacyChecked = !privacyChecked" :key="privacyChecked">
                                    <input type="checkbox" id="ch-1" :checked="privacyChecked"/>
                                    <span class="checkmark"></span>
                                    <span class="archivo color-white">I have read and accept the <router-link to="/privacy" class="archivo text-normal color-white" @click="$emit('close', false)">Privacy Policy</router-link>.</span>
                                </label>
                                <label class="custom-checkbox d-flex" for="ch-2" @click="agreeChecked = !agreeChecked" :key="agreeChecked">
                                    <input type="checkbox" id="ch-2" :checked="agreeChecked"/>
                                    <span class="checkmark"></span>
                                    <span class="archivo color-white">I want to receive notifications about the latest products, events and cutting-edge ideas.</span>
                                </label>
                            </div>
                            <div><UiButton name="Send" @click="send" :disabled="!privacyChecked" :key="privacyChecked"/></div>
                        </div>
                    </form>
                </div>
                <div v-else>
                    <div class="d-flex column align-center message">
                        <template v-if="!called">
                            <div><UiIcon :name="isSuccess ? 'success' : 'error'"></UiIcon></div>
                            <p v-if="isSuccess" class="inter text-normal color-white b-modal-msg">
                                Your application has been accepted. <br/>You will be contacted shortly for further details.
                            </p>
                            <p v-else class="inter text-normal color-white b-modal-msg">Something went wrong. <br/>Please try again!</p>
                            <div><UiButton name="Continue" @click="isSend = false, isSuccess = false, privacyChecked = false"/></div>
                        </template>
                        <template v-else>
                            <div><UiIcon :name="parentSuccess ? 'success' : 'error'"></UiIcon></div>
                            <p v-if="parentSuccess" class="inter text-normal color-white b-modal-msg">
                                Your application has been accepted. <br/>You will be contacted shortly for further details.
                            </p>
                            <p v-else class="inter text-normal color-white b-modal-msg">Something went wrong. <br/>Please try again!</p>
                            <div><UiButton name="Continue" @click="$emit('close', false)"/></div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {ref, watch} from "vue";
import UiButton from './UiButton.vue';
import UiInput from './UiInput.vue';
import UiIcon from "./UiIcon.vue";
const props = defineProps(['modelValue', 'parentSuccess', 'called', 'second']);

const isClosed = ref(false);
const isSend = ref(false);
const isSuccess = ref(false);
const privacyChecked = ref(false);
const agreeChecked = ref(false);

const data = ref({
    name: "",
    email: "",
    subject: "",
    message: ""
})

const resetForm = () => {
    data.value = {
        name: "",
        email: "",
        subject: "",
        message: ""
    }
}

watch(props.modelValue, () => {
    if(!props.modelValue) {
        resetForm();
        isSend.value = false;
        isSuccess.value = false;
        privacyChecked.value = false;
    }
})

const isError = ref(false);
const emailRegex = ref(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);

const responseData = ref(null);
const fetchData = (body) => {
    const request = {
        method: 'POST',
        body: body,
        mode: "no-cors"
    }

    fetch('https://license.darmius.kz/mailsend', request)
    .then(response => {
        isSuccess.value = true;
        isSend.value = true;
        resetForm();
    })
    .catch(e => {
        isSuccess.value = false;
        isSend.value = true;
        resetForm();
    })
}

const send = () => {
    if(privacyChecked.value) {
        isError.value = false;
        for(const key in data.value) {
            if(key === 'email' && !emailRegex.value.test(data.value[key]) || (key === 'name' && data.value['name'].toString().trim().length === 0)) isError.value = true;
            if(data.value.name.toString().trim().length == 0) data.value.name = '';
        }
        if(!isError.value) {
            const formdata = new FormData();
            formdata.append('id', 'es-entertainment');
            formdata.append('name', data.value.name);
            formdata.append('email', data.value.email);
            formdata.append('emailto', "info@es-entertainment.info");
            formdata.append('subject', data.value.subject);
            formdata.append('message', data.value.message);
            fetchData(formdata)
        }
    }
}
</script>

<style lang="scss" scoped>
.b-modal {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 15;
    a {
        color: inherit !important;
    }
    &-msg {
        width: 45%;
        text-align: center;
        margin: 0 auto;
    }
    &-wrapper {
        position: fixed;
        width: 100%;
        height: 100%;
        background: rgba(31, 31, 31, 0.88);
        top: 0;
        left: 0;
        z-index: 100;
    }
    &-content {
        position: fixed;
        z-index: 110;
        width: 50%;
        min-width: 640px;
        max-width: 700px;
        left: 50%;
        top: 50%;
        border-radius: 15px;
        background: radial-gradient(100.21% 115.18% at 0% -3.76%, #4F71C4 0%, #36BAC1 100%), #4F75C4;
        padding: 40px 65px 50px;
        transform: scale(1) translate(-50%, -50%);
        &.hide {
            animation: blowExit .2s;
        }
        &.show {
            animation: blow .4s;
        }
        &__header {
            text-align: center;
            font-size: 40px;
            font-weight: 600;
            margin-bottom: 24px;
        }
        &-div {
            position: relative;
        }
        & .message {
            p {
                margin: 22px 0 32px;
            }
        }
    }
    &-close {
        position: absolute;
        top: 0;
        right: -20px;
    }
    &-form {
        gap: 16px;
        &__first {
            gap: 16px;
        }
        input {
            padding: 16px;
        }
        &__checkbox {
            gap: 23px;
            margin-top: 9px;
        }
    }
    input[type="checkbox"] {
        display: none;
    }
}
.custom-checkbox {
  font-size: 14px;
  line-height: 22px;
//   gap: 12px;
  & span:nth-child(3) {
    padding-left: 12px;
  }  
  &:hover {
    cursor: pointer;
    .checkmark{
        border: 2px solid rgba(39, 39, 39, 0.40);
    }
  }
  & + .custom-checkbox {
    margin-top: 12px;
  }
  & span > span {
    text-decoration: underline;
  }
  .checkmark {
    display: inline-block;
    width: 20px;
    min-width: 20px;
    height: 20px;
    min-height: 20px;
    border-radius: 6px;
    border: 2px solid $white;
    background: $white;
    backdrop-filter: blur(13px);
    cursor: pointer;
    transition: border .3s ease;
  }
  input[type="checkbox"]:checked + .checkmark {
    background-size: 60% 60%;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url("@/assets/icons/check.svg");
  }
  input[type="checkbox"]:checked + .checkmark::before {
    display: block;
  }
}

@keyframes blow {
    0% {
        transform: scale(0.5) translate(-75%, -75%);
    }
    99% {
        transform: scale(1) translate(-50%, -50%);
    }
}

@keyframes blowExit {
    0% {
        transform: scale(1) translate(-50%, -50%);
    }
    100% {
        transform: scale(0) translate(-100%, -100%);
    }
}

@media screen and (max-width: 1200px) {
    .b-modal-form {
        &__first {
            flex-direction: column;
        }
        &__checkbox {
            flex-direction: column;
            & > div:nth-child(2) {
                margin-left: auto;
            }
        }
    }
}

@media screen and (max-width: 900px) {
    .b-modal-content {
        width: 80%;
        min-width:530px;
    }
}

@media screen and (max-width: 600px) {
    .b-modal-content {
        width: 90%;
        max-width:530px;
        min-width: 0 !important;
        padding: 30px 24px;
        &__header {
            font-size: 28px;
            margin-bottom: 17px;
        }
    }
    .b-modal-close {
        right: 0;
    }
    .b-modal-form {
        &__checkbox {
            gap: 0;
        }
    }
    .b-modal-msg {
        width: 60%;
    }
}
</style>