<template>
    <div class="input-container">
        <textarea v-if="isTextarea" class="input" :value="modelValue" @input="handleInput" :placeholder="placeholder" :class="{ 'error': hasError || isError, 'mob': mobcenter }" ></textarea>
        <input v-else class="input" :value="modelValue" @input="handleInput" :placeholder="placeholder" :class="{ 'error': hasError || isError, 'mob': mobcenter }" :type="inputType">
        <span v-if="hasError || isError" class="error-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M18 10C18 12.1217 17.1571 14.1566 15.6569 15.6569C14.1566 17.1571 12.1217 18 10 18C7.87827 18 5.84344 17.1571 4.34315 15.6569C2.84285 14.1566 2 12.1217 2 10C2 7.87827 2.84285 5.84344 4.34315 4.34315C5.84344 2.84285 7.87827 2 10 2C12.1217 2 14.1566 2.84285 15.6569 4.34315C17.1571 5.84344 18 7.87827 18 10ZM10 5C10.1989 5 10.3897 5.07902 10.5303 5.21967C10.671 5.36032 10.75 5.55109 10.75 5.75V10.25C10.75 10.4489 10.671 10.6397 10.5303 10.7803C10.3897 10.921 10.1989 11 10 11C9.80109 11 9.61032 10.921 9.46967 10.7803C9.32902 10.6397 9.25 10.4489 9.25 10.25V5.75C9.25 5.55109 9.32902 5.36032 9.46967 5.21967C9.61032 5.07902 9.80109 5 10 5ZM10 15C10.2652 15 10.5196 14.8946 10.7071 14.7071C10.8946 14.5196 11 14.2652 11 14C11 13.7348 10.8946 13.4804 10.7071 13.2929C10.5196 13.1054 10.2652 13 10 13C9.73478 13 9.48043 13.1054 9.29289 13.2929C9.10536 13.4804 9 13.7348 9 14C9 14.2652 9.10536 14.5196 9.29289 14.7071C9.48043 14.8946 9.73478 15 10 15Z" fill="#FF3D00"/>
            </svg>
        </span>
    </div>
</template>

<script setup>
import { ref, defineProps, defineEmits, watch } from 'vue';
const props = defineProps({
    placeholder: String,
    isError: Boolean,
    inputType: {
        type: String,
        default: "text"
    },
    isTextarea: Boolean,
    modelValue: String,
    mobcenter: Boolean
})
const emits = defineEmits();

const inputValue = ref(props.modelValue || null);
const hasError = ref(props.isError || false);

const handleInput = (event) => {
    inputValue.value = event.target.value;
    if(hasError.value) validateInput()
    // hasError.value = false;
    emits('update:modelValue', inputValue.value);
};

const validateInput = () => {
    if(props.inputType == 'email') {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if(emailRegex.test(inputValue.value)) hasError.value = false;
        else hasError.value = true;
    } else {
        hasError.value = inputValue.value.trim().length === 0;
        // hasError.value = inputValue.value === "";
    }
};
watch(props.isError, () => {
    hasError.value = props.isError;
}, {deep: true})
watch(inputValue.value, () => {
    emits('update:modelValue', inputValue.value);
})
</script>

<style scoped>
.input-container {
    position: relative;
    width: 100%;
}
.error-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    height: 20px;
    width: 20px;
}
textarea {
    min-height: 98px;
    resize: none;
    width: 100%;
    overflow: hidden;
}

@media screen and (max-width: 600px) {
    textarea {
        min-height: 117px !important;
    }
    .mob::placeholder {
        text-align: center;
    }
    .mob {
        padding: 10px !important;
    }
}
</style>
  