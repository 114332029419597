<template>
    <div 
        class="circle d-flex justify-center align-center" 
        @mouseover="hover = true" 
        @mouseleave="hover = false" 
        :class="{'second': isSecond, 'disabled': disabled}"
        :style="disabled ? 'cursor: not-allowed;' : ''"
    >
        <div class="circle--animated" :class="{'show': hover && !disabled}"></div>
        <div class="circle--main d-flex justify-center">
            <div class="d-flex align-center">
                <p>{{ name }}</p>
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                    <g clip-path="url(#clip0_92_3481)">
                        <mask id="mask0_92_3481" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="23" height="23">
                        <path d="M22.0001 1.52588e-05L-2.67029e-05 1.52588e-05L-2.67029e-05 22.0002H22.0001V1.52588e-05Z" fill="white"/>
                        </mask>
                        <g mask="url(#mask0_92_3481)">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.50002 1.52588e-05L22.0001 1.52588e-05V5.50006V16.5001L16.5001 22.0002V5.50006L-2.67029e-05 5.50006L5.50002 1.52588e-05ZM-2.67029e-05 18.1575L-2.67029e-05 11.0001H7.1574L-2.67029e-05 18.1575ZM11.0001 22.0002H3.93555L11.0001 14.9357V22.0002Z" fill="black"/>
                        </g>
                    </g>
                    <defs>
                        <clipPath id="clip0_92_3481">
                        <rect width="22.0002" height="22.0002" fill="white"/>
                        </clipPath>
                    </defs>
                </svg>
            </div>
        </div>
    </div>
</template>

<script setup>
import {ref} from "vue";

const hover = ref(false);
const props = defineProps({
    name: String,
    isSecond: Boolean,
    disabled: Boolean
})
</script>
<style lang="scss" scoped>
button {
    outline: none;
    border: none;

}
.circle {
    z-index: 4;
    width: 160px;
    height: 160px;
    background: rgba(255, 255, 255, 0.47);
    border-radius: 100%;
    position: relative;
    &--main {
        width: 120px;
        height: 120px;
        border-radius: 100%;
        background: $white;
        position: relative;
        z-index: 2;
        & div {
            gap: 10px;
            color: #0E1525;
            font-size: 14px;
            font-weight: 600;
            line-height: normal;
            text-transform: uppercase;
        }
    }
    &--animated {
        // background: $light-grey;
        background: rgba(255, 255, 255, 0.47);
        width: 120px;
        height: 120px;
        border-radius: 100%;
        position: absolute;
        transition: all .4s ease;
        &.show {
            width: 140px;
            height: 140px;
        }
    }
    &:not(.disabled):hover {
        cursor: pointer;
    }
    &.second {
        background: rgba(79, 117, 196, 0.45);
        & .circle--animated {
            background: rgba(79, 117, 196, 0.45);
        }
        & .circle--main {
            background: #4F75C4;
            & div {
                color: $white;
                & svg path {
                    fill: $white;
                }
            }
        }
    }
}

@media screen and (max-width: 900px) {
    .circle {
        width: 140px;
        height: 140px;
        &--main {
            width: 100px;
            height: 100px;
        }
        &--animated {
            width: 100px;
            height: 100px;
            &.show {
                width: 120px;
                height: 120px;
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .circle {
        width: 124px;
        height: 124px;
        &--main {
            width: 98px;
            height: 98px;
        }
        &--animated {
            width: 98px;
            height: 98px;
            &.show {
                width: 111px;
                height: 111px;
            }
        }
    }
}
</style>