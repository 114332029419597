<template>
    <div class="ui-form d-flex">
        <div class="ui-form__left d-flex align-start">
            <div class="ui-form__icon" :class="{'little': isLittle}"><UiIcon name="star" /></div>
            <div class="ui-form__header">
                <div class="ui-form__img"><UiIcon name="star" /></div>
                <p class="color-white uppercase" :class="isLittle ? 'text-l' : 'text-xl'">{{ title }}</p>
                <p class="ui-form__header-subtitle text-normal color-white inter">{{ subtitle }}</p>
                <form class="d-flex column">
                    <div class="d-flex justify-center">
                        <UiInput placeholder="Name" v-model="data.name" :isError="isError && data.name.toString().trim().length == 0" :key="isError" mobcenter/>
                        <UiInput placeholder="E-mail" v-model="data.email" inputType="email" :isError="isError && !emailRegex.test(data.email)" :key="isError && !emailRegex.test(data.email)" mobcenter/>
                    </div>
                    <UiInput placeholder="Your message" v-model="data.message" isTextarea :key="isError" mobcenter />
                </form>
            </div>
            <div class="ui-form__btn"><UiButton name="Send" :isSecond="isSecond" @click="send"/></div>
        </div>
        <div class="ui-form__right d-flex column justify-end">
            <div>
                <UiIcon name="colored-logo" />
            </div>
            <div class="d-flex ui-form__right--text">
                <!-- <a class="address" href="https://www.google.com/search?q=office+10b%2C+mor+workspace%2C+treloggan+lane%2C+newquay%2C+england%2C+tr7+2fp&rlz=1C1GCEU_ruKZ1079KZ1079&oq=&gs_lcrp=EgZjaHJvbWUqCQgDEEUYOxjCAzIJCAAQRRg7GMIDMgkIARBFGDsYwgMyCQgCEEUYOxjCAzIJCAMQRRg7GMIDMgkIBBBFGDsYwgMyCQgFEEUYOxjCAzIJCAYQRRg7GMIDMgkIBxBFGDsYwgPSAQkxODc0ajBqMTWoAgiwAgE&sourceid=chrome&ie=UTF-8" target="_blank"><p>Suite 2.071 Fox <br/>Court, 14 Gray's <br/>Inn Road, London, <br/>England, WC1X 8HN</p></a> -->
                <p class="address color-black inter text-normal sixteen">Suite 2.071 Fox <br/>Court, 14 Gray's <br/>Inn Road, London, <br/>England, WC1X 8HN</p>

                <div>
                    <a class="inter text-normal sixteen" href="https://www.linkedin.com/company/es-en/" target="_blank">Linkedin</a><br/>
                    <router-link class="inter text-normal sixteen color-black" to="/privacy">Privacy Policy</router-link><br/>
                    <a style="white-space: nowrap;" class="color-black inter text-normal sixteen" href="mailto:info@es-entertainment.info">info@es-entertainment.info</a>
                </div>
            </div>
        </div>
    </div>
    <UiModal v-if="isSend && !closeModal" :called="!closeModal" :second="isSend" :parent-success="isSuccess" @close="isSuccess = false, closeModal = true, isSend = false"/>
</template>

<script setup>
import {ref, watch, onMounted, onUnmounted} from "vue";
import UiIcon from "@/components/Ui/UiIcon.vue";
import UiButton from "./UiButton.vue";
import UiInput from "@/components/Ui/UiInput.vue";
import UiModal from "@/components/Ui/UiModal.vue";

const closeModal = ref(false);
const props = defineProps({
    title: String,
    subtitle: String,
    isLittle: Boolean
})

const data = ref({
    name: "",
    email: "",
    message: ""
})
const isError = ref(false);
const emailRegex = ref(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
const responseData = ref(null);

onMounted(() => {
   window.addEventListener('resize', handleResize);
});

const isSecond = ref(window.innerWidth < 600);

const handleResize = () => {
    isSecond.value = window.innerWidth < 600;
};

watch(window, () => {handleResize}, {deep: true})

const isSuccess = ref(false);
const isSend = ref(false);
const fetchData = (formdata) => {
    const request = {
        method: 'POST',
        body: formdata,
        mode: 'no-cors',
    }

    fetch('https://license.darmius.kz/mailsend', request).then(response => {
        console.log(response)
        isSuccess.value = true;
        isSend.value = true;
        closeModal.value = false;
        data.value = {
        name: "",
        email: "",
        subject: "",
        message: ""
    }
    })
    .catch(e => {
        isSuccess.value = false;      
        isSend.value = true;
        closeModal.value = false;
        data.value = {
        name: "",
        email: "",
        subject: "",
        message: ""
    }
    })
}

const send = () => {
    isError.value = false;
    
    for(const key in data.value) {
        if(data.value['name'].toString().trim().length === 0 || (key === 'email' && !emailRegex.value.test(data.value[key]))) isError.value = true;
        if(data.value.name.toString().trim().length == 0) data.value.name = '';
        // else isError.value = false;
    }
    if(!isError.value) {
        const formdata = new FormData();
        formdata.append('id', 'es-entertainment');
        formdata.append('name', data.value.name);
        formdata.append('email', data.value.email);
        formdata.append('emailto', "info@es-entertainment.info");
        formdata.append('message', data.value.message);
        formdata.append('subject', '\n');

        fetchData(formdata)
    }
}
</script>
<style lang="scss" scoped>
.ui-form {
    border-radius: 20px;
    background: linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.71) 100%);
    padding: 10px;
    position: relative;
    z-index: 2;
    &__header {
        &-subtitle {
            margin: 10px 230px 24px 0;
        }
    }
    &__img {
        display: none;
    }
    &__icon {
        min-width: 57px;
        max-width: 57px;
        height: auto;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
        &.little {
            min-width: 33px;
            max-width: 33px;
        }
    }
    &__left {
        border-radius: 10px;
        background: radial-gradient(100.21% 115.18% at 0% -3.76%, #4F71C4 0%, #36BAC1 100%), rgba(79, 113, 196, 0.89);
        padding: 57px 112px 77px 50px;
        width: 60%;
        gap: 13px;
        position: relative;
        & form, form > div:first-child {
            gap: 14px;
        }
    }
    &__right {
        padding: 37px;
        &--text {
            margin-top: 41px;
            width: 100%;
            & .address {
                width: 100%;
                opacity: 0.4;
                margin-right: 70px;
            }
            & > div a {
                text-decoration: underline;
                color: #000 !important;
            }
            & > div {
                &:visited, &:focus, &:active{
                   color: #000 !important;
                }
            }
        }
        & img {
            object-fit: contain;
        }
    }
    &__btn {
        position: absolute;
        left: 100%;
        bottom: 0;
        transform: translate(-130px, 90px);
    }
}

@media screen and (max-width: 600px) {
    .text-l {
        line-height: 52px;
        font-size: 36px;
    }
    .inter {
        font-family: 'Archivo', sans-serif;
        width: 90%;
        margin-left: auto !important;
        margin-right: auto !important;
    }
    .ui-form {
        &__left {
            padding: 30px 10px 10px !important;
            position: relative;
            gap: 0;
            form {
                gap: 10px;
            }
        }
        &__right {
            padding: 127px 10px 30px !important;
        }
        &__img {
            width: 25px !important;
            height: 25px !important;
        }
        &__header{
            width: 100% !important;
        }
        &__btn {
            transform: translate(-50%, 60px) !important;
        }
    }
}

@media screen and (max-width: 700px) {
    .ui-form__right--text {
        margin-top: 30px !important;
    }
    .ui-form__btn {
        transform: translate(-50%, 90px);
    }
}

@media screen and (min-width: 1025px) and (max-width: 1500px) {
    .ui-form__left {
        padding: 57px 50px 77px 15px;
        flex: 1 0 60%;
        gap: 10px;
    }
    .ui-form__right {
        padding: 37px 20px;
        &--text .address {
            margin-right: 10px;
        }
    }
    .ui-form__icon {
        min-width: 45px;
        max-width: 45px;
    }
}

@media screen and (max-width: 1025px) {
    .ui-form {
        flex-direction: column;
        &__left {
            width: 100%;
            justify-content: center;
            padding: 30px;
        }
        &__right {
            padding: 135px 10px 20px;
            text-align: center;
            &--text {
                flex-direction: column;
                & > p:first-child {
                    margin: 0 auto 30px;
                }
                a {
                    display: inline-block;
                    margin-top: 16px;
                }
            }
        }
        &__icon {
            display: none;
        }
        &__img {
            display: block;
            width: 50px;
            height: 50px;
            margin: 0 auto;
            & img {
                width: 100%;
                height: 100%;
            }
        }
        &__header {
            text-align: center;
            width: 90%;
            max-width: 550px;
            &-subtitle {
                margin: 10px 0 20px;
            }
        }
        form > div:first-child {
            flex-direction: column;
            gap: 10px;
        }
        &__btn {
            left: 50%;
            transform: translate(-50%, 120px);
        }
    }
}

</style>