import { createRouter, createWebHashHistory, afterEach, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/whoweare',
    name: 'whoweare',
    component: function () {
      return import('@/views/WhoWeAreView.vue')
    }
  },
  {
    path: '/digital',
    name: 'digital',
    component: function () {
      return import('@/views/DigitalMarketingView.vue')
    }
  },
  {
    path: '/marketing',
    name: 'marketing',
    component: function () {
      return import('@/views/MarketingView.vue')
    }
  },
  {
    path: '/content',
    name: 'content',
    component: function () {
      return import('@/views/ContentView.vue')
    }
  },
  {
    path: '/analysis',
    name: 'marketing-analusis',
    component: function () {
      return import('@/views/AnalysisView.vue')
    }
  },
  {
    path: '/brand',
    name: 'brand',
    component: function () {
      return import('@/views/BrandDevelopmentView.vue')
    }
  },
  {
    path: '/careers',
    name: 'careers',
    component: function () {
      return import('@/views/CareersView.vue')
    }
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: function () {
      return import('@/views/PrivacyView.vue')
    }
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.afterEach((to, from) => {
  window.scrollTo(0, 0);
})

export default router
