<template>
    <div class="menu" :class="{'show': modelValue, 'hide': isClosed}">
        <div class="menu-wrapper" @click="isClosed = true, $emit('update:modelValue', false)" :class="{'show': modelValue, 'hide': isClosed}"></div>
        <transition name="menu-slide">
        <div class="menu-content" :class="{'show active': modelValue, 'hide': isClosed}">
            <div class="overlay"></div>
            <div class="menu-content__body d-flex column">
                <div class="menu-content__header d-flex justify-between aling-center">
                    <div class="menu-content__btn d-flex align-center uppercase color-white" @click="ismodal = true, $emit('modal', true)" style="cursor: pointer;"><span>Contact us</span></div>
                    <svg @click="isClosed = true, $emit('update:modelValue', false)" style="cursor: pointer;" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                        <rect x="1.47656" y="10.1924" width="13" height="40" rx="2.46667" transform="rotate(-45 1.47656 10.1924)" fill="white"/>
                        <rect width="13" height="40" rx="2.46667" transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 38.4766 10.1924)" fill="white"/>
                    </svg>
                </div>
                <router-link to="/" class="d-flex align-center">
                    <p class="menu-text--main color-white" :class="{'disable': route.name === 'home'}">Home</p>
                </router-link>
                <router-link to="/whoweare" class="d-flex align-center">
                    <p class="menu-text--main color-white" :class="{'disable': route.name === 'whoweare'}">Who We Are</p>
                </router-link>
                <p class="menu-text--main color-white unlink">What We Do</p>
                <div class="d-flex column links">
                    <router-link to="/digital" class="d-flex align-center links__item">
                        <p class="color-white" :class="{'disable': route.name === 'digital'}">Digital Marketing Strategy</p>
                    </router-link>
                    <router-link to="/brand" class="d-flex align-center links__item">
                        <p class="color-white" :class="{'disable': route.name === 'brand'}">Brand Development</p>
                    </router-link>
                    <router-link to="/content" class="d-flex align-center links__item">
                        <p class="color-white" :class="{'disable': route.name === 'content'}">Content development</p>
                    </router-link>
                    <router-link to="/marketing" class="d-flex align-center links__item">
                        <p class="color-white" :class="{'disable': route.name === 'marketing'}">Marketing Research</p>
                    </router-link>
                    <router-link to="/analysis" class="d-flex align-center links__item">
                        <p class="color-white" :class="{'disable': route.name === 'analysis'}">Marketing analysis & development</p>
                    </router-link>
                </div>
                
                <router-link to="/careers" class="d-flex align-center">
                    <p class="menu-text--main color-white" :class="{'disable': route.name === 'careers'}">Careers</p>
                </router-link>

                <div class="menu-content__footer d-flex justify-between align-center">
                    <a class="color-white inter text-normal" style="cursor: pointer" href="mailto:info@es-entertainment.info">info@es-entertainment</a>
                    <a class="color-white inter text-normal" style="cursor: pointer" href="https://www.linkedin.com/company/es-en/" target="_blank">Linkedin</a>
                </div>
            </div>
        </div>
    </transition>
    </div>
    <UiModal v-show="ismodal" v-model="ismodal" :key="ismodal"/>
</template>

<script setup>
import {ref} from "vue";
import { useRoute } from 'vue-router';
import UiModal from "@/components/Ui/UiModal.vue";

const props = defineProps(['modelValue']);
const ismodal = ref(false);
const isClosed = ref(false);

const isDisabled = ref(false);
const route = useRoute();
</script>

<style lang="scss" scoped>
.menu {
    position: fixed;
    top: -100%;
    z-index: 10;
    
    &.hide, .hide {
        animation: fadeOutUp .7s ease forwards;
    }
    &.show, .show {
        animation: fadeInDown .7s ease forwards;
    }
    &-wrapper {
        position: fixed;
        z-index: 4;
        top: -100vh;
        left: 0;
        width: 100%;
        height: 100%;
    }
    // background: rgba(0, 0, 0, 0.5);
    & a {
        text-decoration: none;
        &:visited, &:hover, &:active {
            color: #fff;
        }
    }
    
    &-content {
        position: fixed;
        right: 0;
        top: -100vh;
        width: 30vw;
        min-width: 420px;
        height: 100%;
        z-index: 5;
        border-radius: 20px 0px 0px 20px;
        background: rgba(255, 255, 255, 0.73);
        padding: 10px;
        transition: all 1s ease;
        &__body {
            border-radius: 20px;
            background: radial-gradient(92.09% 98.31% at 26.92% 4.44%, #4F71C4 0%, #36BAC1 100%);
            width: 100%;
            height: 100%;
            padding: 40px;
            position: relative;
            z-index: 3;
            gap: 4vh;
            & p {
                transition: hover .3s ease;
                &:not(.unlink):hover {
                    color: #39B4C2;
                }
            }
        }
        &__btn {
            padding: 8px 16px;
            border-radius: 50px;
            background: rgba(255, 255, 255, 0.22);
            transition: all .2s ease;
            span {
                font-size: 20px;
                font-weight: 600;
            }
            &:hover {
                background: rgba(255, 255, 255, 0.40);
                opacity: 0.7;
            }
        }
        &__header {
            & svg {
                transition: opacity .3s ease;
                &:hover {
                    opacity: 0.7;
                }
            }
        }
        &__footer {
            margin-top: auto;
        }
    }
    &-text {
        &--main {
            font-size: 6vh;
            font-weight: 600;
            line-height: 4vh;
            &.disable {
                opacity: 0.63;
            }
        }
    }
}
.links {
    gap: 1.7vh;
    &__item {
        > p {
            font-size: 2.4vh;
            font-weight: 600;
            line-height: 31.5px;
            &.disable {
                opacity: 0.63;
            }
        }
    }
}

@keyframes fadeInDown {
    0% {
        top: -100vh;
    }
    50% {
        top: 3vh;
    }
    100% {
        top: 0;
    }
}

@keyframes fadeOutUp {
    0% {
        top: 0;
    }
    50% {
        top: -100vh;
    }
}

@media screen and (max-width: 900px) {
    .menu-content {
        min-width: 380px;
        &__body {
            text-align: center;
            justify-content: center;
        }
        &__footer {
            p {
                font-size: 12px;
            }
        }
        &__header {
            margin-bottom: 15px;
        }
    }
    .menu{
        &-text {
            // &--main {
            //     font-size: 58px;
            //     line-height: 64px;
            // }
        }
        & a {
            justify-content: center;
        }
    }
}
@media screen and (max-width: 500px) {
    .menu-content {
        min-width: 100% !important;
        &__footer a {
            font-size: 12px;
        }
    }
}
</style>