<template>
    <!-- <div class="animated-block d-flex" @mouseover="checkActiviness('all', 'over')" @mouseleave="checkActiviness('all', 'leave')">         -->
    <div class="animated-block d-flex" @mouseleave="checkActiviness('all', 'leave')">        
        <div class="animated-block__item" :class="{'active': state.is_active1}">
            <div
                class="full d-flex"
                :class="{'initial': !state.is_active1, 'close': state.is_active1, 'layer_frame_632013--close': state.is_active1}"
            >
                <div class="full-item d-flex column" v-if="state.is_active1" @mouseleave="checkActiviness('is_active1', 'leave')">
                    <UiIcon name="accordion1"/>
                    <p class="text-xl full-item__text">01/</p>  
                    <p class="uppercase text-l">We excel in the art of Design.</p>
                    <p class="inter text-normal">With our creative marketing team at the helm, we delve deep into understanding your brand, allowing us to effectively convey your message to your target audience.</p>
                </div>
                <div class="full-item d-flex column" v-else @mouseover="checkActiviness('is_active1', 'over')">
                    <p class="uppercase text-l color-black">01/ <br class="break"/> We excel in the art of Design.</p>
                </div>
            </div>
        </div>
        <div class="animated-block__item" :class="{'active': state.is_active2}">
            <div
                class="full d-flex"
                :class="{'initial': !state.is_active2, 'layer_frame_632013--close': state.is_active2}"
                @mouseover="() => {if(!state.is_active2) checkActiviness('is_active2', 'over')}"
            >
                <div class="full-item d-flex column"  v-if="state.is_active2" @mouseover="checkActiviness('is_active2', 'over')">
                    <UiIcon name="accordion2" png/>
                    <p class="text-xl full-item__text">02/</p>  
                    <p class="uppercase text-l">Optimization is at the core of our approach.</p>
                    <p class="inter text-normal">We ensure that our efforts yield tangible results for our clients. Rigorously measuring outcomes is a non-negotiable part of our process.</p>
                </div>
                <div class="full-item d-flex column"  v-else @mouseleave="checkActiviness('is_active2', 'leave')">
                    <p class="uppercase text-l color-black">02/ <br class="break"/>Optimization is at the core of our approach.</p>
                </div>
            </div>
        </div>
        <div class="animated-block__item" :class="{'active': state.is_active3}">
            
            <div
                class="full d-flex"
                :class="{'initial': !state.is_active3}"
                @mouseover="() => {if(!state.is_active3) checkActiviness('is_active3', 'over')}"
            >
                <div class="full-item d-flex column"  v-if="state.is_active3" @mouseleave="checkActiviness('is_active3', 'leave')">
                    <UiIcon name="accordion3" />
                    <p class="text-xl full-item__text">03/</p>  
                    <p class="uppercase text-l">Promotion is our forte.</p>
                    <p class="inter text-normal">Whether it's launching brand new campaigns, products, or services, we specialize in reaching the right audience through a multi-faceted approach, encompassing online, offline, and emerging media.</p>
                </div>
                <div class="full-item d-flex column"  v-else @mouseover="checkActiviness('is_active3', 'over')">
                    <p class="uppercase text-l color-black">03/ <br class="break"/> Promotion is our forte.</p>
                </div>
            </div>
        </div>
        <div class="animated-block__item" :class="{'active': state.is_active4}">
            <div
                class="full d-flex"
                :class="{'initial': !state.is_active4}"
                @mouseover="() => {if(!state.is_active4) checkActiviness('is_active4', 'over')}"
            >
                <div class="full-item d-flex column"  v-if="state.is_active4" @mouseleave="checkActiviness('is_active4', 'leave')">
                    <UiIcon name="accordion4" />
                    <p class="text-xl full-item__text">04/</p>  
                    <p class="uppercase text-l">Our support goes beyond expectations.</p>
                    <p class="inter text-normal">As your dedicated partner, we provide complete creative, operational, and technical assistance. Our all-inclusive package of marketing services caters to every aspect of your brand's success.</p>
                </div>
                <div class="full-item d-flex column"  v-else @mouseover="checkActiviness('is_active4', 'over')">
                    <p class="uppercase text-l color-black">04/ <br class="break"/> Our support goes beyond expectations.</p>
                </div>
            </div>
        </div> 
    </div>
</template>

<script setup>
import {ref, watch} from "vue";
import UiIcon from "@/components/Ui/UiIcon.vue";

const state = ref({
    is_active1: true,
    is_active2: false,
    is_active3: false,
    is_active4: false
})

const activeIndex = ref(0);

function toggleBlock(index) {
  activeIndex.value = activeIndex.value === index ? null : index;
}

const inMain = ref(false)

const checkActiviness = (item, type) => {
    switch(item) {
        case "all": 
            if(type === 'over') {
                inMain.value = true;
            } else {
                inMain.value = false;
            }
            break;
        case "is_active1":
            if(type === 'over') {
                state.value.is_active1 = true;
                state.value.is_active2 = false;
                state.value.is_active3 = false;
                state.value.is_active4 = false;
            } else {
                state.value.is_active1 = false;
            }
            break;
        case "is_active2":
            if(type === 'over') {
                state.value.is_active2 = true;
                state.value.is_active1 = false;
                state.value.is_active3 = false;
                state.value.is_active4 = false;
            } else {
                state.value.is_active2 = false;
            }
            break;
        case "is_active3":
            if(type === 'over') {
                state.value.is_active3 = true;
                state.value.is_active1 = false;
                state.value.is_active2 = false;
                state.value.is_active4 = false;
            } else {
                state.value.is_active3 = false;
            }
            break;
        case "is_active4":
            if(type === 'over') {
                state.value.is_active4 = true;
                state.value.is_active1 = false;
                state.value.is_active2 = false;
                state.value.is_active3 = false;
            } else {
                state.value.is_active4 = false;
                state.value.is_active1 = true;
            }
            break;    
    }
}

watch(state, () => {
    if(!state.value.is_active1 && !state.value.is_active2 && !state.value.is_active3 && !state.value.is_active4) state.value.is_active1 = true;
}, {deep: true})
</script>

<style lang="scss" scoped>
@mixin transition($transition...) {
    -moz-transition:    $transition;
    -ms-transition:      $transition;
    -webkit-transition: $transition;
    transition:         $transition;
}
.animated-block {
    border-radius: 25px;
    background: rgba(255, 255, 255, 0.47);
    overflow: hidden;
    min-height: 525px;
    height: 550px;
    padding: 10px;
    width: 100%;
    overflow: hidden;
    position: relative;
    
    &__item {
        display: flex;
        & .full {
            background: radial-gradient(100.21% 115.18% at 0% -3.76%, #4F71C4 0%, #36BAC1 100%), rgba(255, 255, 255, 0.94);
            color: $white;
            gap: 8px;
            padding: 29px 29px 25px 24px;
            width: 100%;
            // flex: 1 0 100%;
            height: 100%;
            border-radius: 15px;
            &-item img {
                margin: 0 0 auto auto;
            }
        }
     
        & .initial {
            background: rgba(255, 255, 255, 0.94) !important;
            height: 100%;
            width: 150px !important;
            position: relative;
            padding: 60px 32px 65px 34px;
            flex: 1 0 150px;
            & p {
                transform-origin: top left;
                transform: rotate(-90deg) translateY(-50%);
                color: #131417;
                position: absolute;
                top: 87%;
                left: 55%;
                width: 385px;
            }
        }
        & + .animated-block__item {
            margin-left: 10px;
        }
    }
}
.break {
    display: none;
}

@keyframes layer_frame_632013__size_878260 {
  0% {
    width: auto;
    height: 100%;
  }
  100% {
    width: 150px;
    height: 100%;
  }
}
@keyframes layer {
  0% {
    width: 150px;
    height: 100%;
  }
  100% {
    width: 346px;
    height: 100%;
  }
}
// .layer_frame_632013 {
// //   animation: layer_frame_632013__size_878260 1s cubic-bezier(0.5, 0.35, 0.15, 1) 1s 10 normal forwards;
// }

// .layer_frame_632013--close {
// //   animation: layer 1s cubic-bezier(0.5, 0.35, 0.15, 1) 1s 10 normal forwards;
// }

@media screen and (max-width: 900px) {
    .break {
        display: block;
    }
    .animated-block {
        flex-direction: column;
        height: auto;
        &__item {
            & .initial {
                width: 100% !important;
                padding: 15px !important;
                p {
                    top: 0;
                    left: 0;
                    transform: rotate(0);
                    position: relative !important;
                    width: 100%;
                }
            }
            & .full {
                align-items: center;
                text-align: center;
                & img {
                    margin: 0 auto;
                    width: 138px;
                }
                &-item {
                    flex: 1 0 100%;
                    & .text-xl {
                        font-size: 28px;
                        line-height: 38px;
                    }
                    & .text-l {
                        font-weight: 500;
                    }
                }
                &-item__text {
                    margin: 40px 0 8px;
                    & + .text-l {
                        margin-bottom: 16px;
                    }
                }
            }
            & + .animated-block__item {
                margin-left: 0 !important;
                margin-top: 10px;
            }
        }
    }
}
</style>