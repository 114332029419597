<template>
    <img :src="!png ? require(`@/assets/icons/${name}.svg`) : require(`@/assets/icons/${name}.png`)" alt="icon" :class="color.length ? `${color}` : ''" />
    <!-- <logo1 /><div v-if="svgContent" v-html="svgContent"> -->
    <!-- </div> -->
</template>

<script setup>
const props = defineProps({
    name: String,
    color: {
        type: String,
        default: ""
    },
    png: Boolean
})
import { ref } from 'vue';

// const props = defineProps(['name']);
const svgContent = ref(null);

// import(`@/assets/icons/${props.name}.svg`).then((module) => {
//   svgContent.value = module.default;
// });

</script>

<style lang="scss" scoped>
img {
    &.black {
        color: $black;
        svg path {
            fill: $black;
        }
    }
}
</style>