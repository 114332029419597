<template>
  <div class="home">
    <div class="overlay"></div>
    <div class="home__first">
      <div class="home__first--main">
        <BaseHeader />
        <div class="container">
          <div class="content-1">
            <div class="text">
              <div class="text-xxl color-primary uppercase">
                <span class="content-1__num roboto color-black text-normal">01/</span>
                <span>Empower Your Brand </span><br/><span class="color-black">And Unleash Success</span>
                <div class="content-1__star"><UiIcon name="star-black"></UiIcon></div>
              </div>
              <div class="text__sub text-xl color-dark uppercase">With Our Experts</div>
              <div class="text__low text-normal inter color-black">Step into a realm of endless possibilities, where your brand's success is our topmost priority.</div>
              <div class="text__low text-normal inter color-black">As you embark on this transformative journey with us, expect nothing short of excellence, dedication and a touch of magic.</div>
            </div>
          </div>
        </div>
        <div class="content-1__img">
          <div class="home__first-btn"><router-link to="/whoweare"><UiButton name="Explore" /></router-link></div>
          <img src="@/assets/images/home/image1.jpg" alt="image-1 people" class="img-cover"/>
        </div>
      </div>
    </div>
    
    <div class="home__second">
      <div class="container index-2">
        <span class="roboto color-white home__second-num">02/</span>
        <div class="home__second-block d-flex align-start justify-between gap-15">
          <div class="home__second-block-head">
            <UiIcon name="star" />
            <span class="uppercase text-xl color-white">BRAND UNIQUE EXPERIENCE</span>
          </div>
          <div>
            <p class="uppercase text-xl color-white">BRAND UNIQUE EXPERIENCE</p>
            <p class="home__second-text text-normal color-white inter">As a one-stop marketing agency, we aim to assist clients in thriving in a changing omnichannel world and leverage their unique strengths to build personalized roadmaps to success. Please feel free to speak with us about how we can help you.</p>
            <p class="text-normal color-white inter">Eastern Star Entertainment is dedicated to helping brands find their creative voice.</p>  
          </div>
          <div class="accordion"><UiHorizontalAccordion /></div>
        </div>
        <div class="home__second-btn"><UiButton name="Contact us" @click="scrollDown" /></div>
      </div>
    </div>

    <div class="home__third index-2">
      <div class="container index-2 content-3">
        <span class="roboto color-black home__third-num">03/</span>
        <div class="content-3__title d-flex align-start justify-start gap-15">
          <UiIcon class="desktop" name="star-black" />
          <UiIcon class="mobile" name="star" />
          <p class="text-normal inter color-black">As a services company, Eastern Star Entertainment is open to partnering with other businesses, organizations and individuals who share our vision and values. We believe that by combining our expertise and resources, we can create mutually beneficial opportunities that drive innovation and growth.</p>
        </div>
        <div class="home__third-text d-flex">
          <UiCards icon="speaker" png title="Digital Marketing Strategy" path="digital" text="Digital marketing strategy involves developing a comprehensive and strategic plan to promote a website online, drive traffic, increase visibility and achieve marketing objectives." />
          <UiCards icon="rocket" png title="Brand Development" path="brand" text="Establishing a strong and distinct brand identity that attracts customers, builds loyalty and stands out in a competitive market." />
          <UiCards icon="light" png title="Marketing Research" path="marketing" text="A marketing research service for a website involves gathering, analyzing and interpreting data to provide insights and recommendations." /> 
          <UiCards icon="pen" png title="Content development" path="content" text="We bolster client marketing efforts, producing a diverse range of content formats, such as research reports, opinion pieces, videos, infographics, slideware and articles." />
          <UiCards icon="chart" png title="Marketing analysis & development" path="analysis" text="We perform competitor analysis, target audience research, and create buyer personas with brand-reflective copy for websites and digital assets." />
        </div>
      </div>
      <div class="home__third-img">
        <img src="@/assets/images/home/image2.png" alt="people" class="img-cover"/>
      </div>
    </div>

    <div class="home__fourth">
      <div class="home__fourth-content relative content-4 container">
        <span class="roboto color-white home__second-num">04/</span>
        <div class="d-flex align-start">
          <UiIcon name="star" />
          <div class="d-flex column content-4__title">
            <p class="uppercase text-xl color-white">Partnerships</p>
            <p class="text-normal color-white inter">As a services company, Eastern Star Entertainment is open to partnering with other businesses, organizations, and individuals who share our vision and values. We believe that by combining our expertise and resources, we can create mutually beneficial opportunities that drive innovation and growth.</p>
          </div>
        </div>
        <div class="d-flex justify-between content-4__img">
          <div><UiIcon name="partner1" /></div>
          <div><UiIcon name="partner2" /></div>
          <div><UiIcon name="partner3" /></div>
        </div>
        <UiForm title="Please fill out the form below to get in touch with us" subtitle="And discover how we can elevate your brand's digital presence." />
      </div>
      <BaseFooter />
    </div>
  </div>
</template>

<script setup>
import BaseHeader from "@/components/Base/BaseHeader.vue";
import BaseFooter from "@/components/Base/BaseFooter.vue";
import UiIcon from "@/components/Ui/UiIcon.vue";
import UiButton from "@/components/Ui/UiButton.vue";
import UiHorizontalAccordion from "@/components/Ui/UiHorizontalAccordion.vue";
import UiCards from "@/components/Ui/UiCards.vue";
import UiForm from "@/components/Ui/UiForm.vue";

const scrollDown = () => {
  window.scrollBy({
    top: document.body.scrollHeight-1800,
    behavior: 'smooth'
  });
}
</script>

<style lang="scss" scoped>
.home {
  border-radius: 20px;
  background: radial-gradient(92.09% 98.31% at 26.92% 4.44%, #4F71C4 0%, #36BAC1 100%);
  position: relative;
  overflow: hidden;
  &__first {
    position: relative;
    &--main {
      position: relative;
      z-index: 3;
    }
    &-btn {
      position: absolute;
      top: -45%;
      left: 50%;
      transform: translate(-50%, 50%);
      a {
        text-decoration: none;
      }
    }
  }
  &__second {
    position: relative;
    padding: 64px 0 155px;
    & .accordion {
      flex: 1 0 65%;
    }
    &-block-head span {
      display: none;
    }
    
    &-num {
      margin-bottom: 24px;
      margin-left: 17px;
      display: block;
    }
    &-btn {
      position: absolute;
      left: 25%;
      top: 90%;
    }
    &-text {
      margin: 20px 15px 18px 0;
    }
  }
  &__third {
    border-radius: 20px;
    background: #F0F1F4;
    padding-top: 64px;
    overflow: hidden;
    &-num {
      margin-left: 17px;
      margin-bottom: 12px;
      display: block;
    }
    &-img {
      height: 460px;
      width: 100%;
      border-radius: 20px;
      overflow: hidden;
      margin-top: 210px;
      img {
        object-position: 20% 40%;
      }
    }
    &-text {
      border-radius: 25px;
      background: rgba(79, 117, 196, 0.47);
      padding: 10px;
      margin-top: 22px;
      flex-wrap: wrap;
      gap: 10px;
      position: absolute;
      top: 100%;
      left: auto;
      right: auto;
      width: 90%;
      z-index: 3;
    }
  }
  &__fourth {
    background: radial-gradient(158.25% 161.75% at 96.73% 87.96%, #4F71C4 0%, #36BAC1 100%), #F0F1F4;
    padding-top: 20px;
    top: -20px;
    margin-bottom: -20px;
    position: relative;
    &-content {
      padding-top: 64px !important;
      // position: relative;
      // z-index: 1;
    }
  }
}
.content-1 {
  border-radius: 20px 20px 0 0;
  background: linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.71) 100%);
  padding: 64px;
  & .text {
    position: relative;
    z-index: 2;
    text-align: center;
    &__low {
      margin: 0 26.3%;
    }
    &__sub {
      margin: 8px 0 20px;
    }
  }
  &__img {
    height: 300px;
    width: 100%;
    position: relative;
    img {
      overflow: hidden;
      border-radius: 20px;
      object-position: 50% 70%;
    }
  }
  &__star{  
    position: absolute;
    right: 0;
    top: 0;
    width: 88px;
    height: 108px;
    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__num {
    position: absolute;
    left: 0;
    top: 15px;
  }
}
.content-3 {
  &__title .mobile {
    display: none;
  }
  &__title > p {
    width: 50%;
  }
}
.content-4 {
  &__title {
    width: 42%;
    margin-left: 13px;
    p + p {
      margin-top: 20px;
      line-height: 24px;
    }
  }
  &__img {
    margin: 64px 0 68px;
    padding: 0 70px;
  }
}

@media screen and (max-width: 1500px) {
  .home__third-text {
    position: relative;
    margin-top: 40px;
    width: 100% !important;
  }
  .home__third-img {
    margin-top: -50px;
  }
}
  
@media screen and (max-width: 600px) {
  .inter {
    font-family: 'Archivo', sans-serif;
    width: 95%;
    margin-left: auto !important;
    margin-right: auto !important;
    font-size: 16px;
  }
  .content-1 {
    padding: 20px 12px 65px;
    & .text {
      & > div:first-child {
        max-width: 300px;
        margin: 0 auto;
      }
      &__low {
        margin: 0;
        width: 95%;
        color: #1E1E1E;
        line-height: 22.4px;
      }
      &__sub {
        margin: 0 0 16px;
      }
      & .text-xxl span:nth-child(2) {
        width: 90%;
        display: inline-block;
      }
    }
    &__img {
        height: 141px;
    }
    &__star {
        width: 31px !important;
        height: auto;
    }
  }
  .home {
    &__first-btn {
      transform: translate(-50%, 0) !important; 
    }
    &__third {
      background: none !important;
      position: relative;
      overflow: initial !important;
      z-index: 2;
      &-text {
        background: rgba(255, 255, 255, 0.47);
      }
      &-img {
        height: 142px;
      }
    }
    &__fourth-content {
      padding-top: 80px !important;
      & img {
        width: 25px;
        height: 25px;
      }
    }
    &__second {
      padding: 64px 0 0 !important;
      &-block {
        text-align: center;
        gap: 0 !important;
        span {
          margin-left: 0 !important;
        }
        img {
          width: 31px;
        }
      }
      &-text {
        margin: 16px 0 10px;
      }
    }
  }
  .content-3 {
    &__title {
      flex-direction: column;
      align-items: center !important;
      gap: 10px !important;
      width: 95%;
      & img {
        width: 31px;
      }
      & > p {
        color: $white !important;
        text-align: center;
        width: 100%;
      }
      & .desktop {
        display: none;
      }
      & .mobile {
        display: block;
      }
    }
  }
  .content-4 {
    &__img {
      margin: 30px 0 65px;
    }
    &__img > div {
      height: 35px;
      & img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    &__title {
      text-align: center;
    }
    &__title p + p {
      margin-top: 16px;
    }
  }
}

@media screen and (min-width: 601px) and (max-width: 1024px) {
    .content-1 {
        padding: 40px 20px 60px;
        & .text {
            &__low {
                margin: 0 15%;
            }
            &__sub {
                margin: 0 0 16px;
            }
            & .text-xxl span:nth-child(2) {
                width: 80%;
                display: inline-block;
            }
        }
        &__star {
            width: 57px !important;
            height: auto;
        }
    }
}

@media screen and (min-width: 1025px) and (max-width: 1300px) {
  .content-1 {
    padding: 58px;
    & .text {
      &__low {
        margin: 0 10%;
      }
      &__sub {
        margin: 6px 0 16px;
      }
    }
    &__star {
      width: 70px !important;
      height: auto;
    }
  }
  .content-4 {
    &__img {
      padding: 0;
    }
  }
}

@media screen and (max-width: 1350px) {
  .content-1__num, .home__second-num, .home__third-num {
    display: none;
  }
  .home__second {
    padding: 64px 0;
    &-block {
      flex-wrap: wrap;
      &-head span {
        display: inline-block;
        margin-left: 10px;
      }
      & > div + div .uppercase {
        display: none;
      }
      & .accordion {
        margin-top: 30px;
      }
    }
    &-btn {
      position: relative;
      left: 50%;
      top: 0;
      width: min-content;
      transform: translateX(-50%);
      margin-top: 30px;
    }
  }
}

@media screen and (max-width: 1025px) {
  .content-4 {
    & > span + div {
    flex-direction: column;
    align-items: center !important;
    }
    &__title {
    width: 100%;
    align-items: center !important;
    }
    &__img {
      gap: 40px;
      flex-wrap: wrap;
      align-items: center !important;
      padding: 10px !important;
      & > div {
          flex: 1 0 100%;
          & img {
              margin: 0 auto;
              display: block;
          }
      }
    }
  }
}
</style>