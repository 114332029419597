<template>
    <header :class="{'fixed': isFixed}">
        <div class="container d-flex justify-between">
            <router-link to="/" class="d-flex align-center">
                <div class="logo d-flex">
                    <UiIcon name="logo1"/>
                    <UiIcon name="logo2" />
                </div>
            </router-link>
            <div class="actions d-flex align-center">
                <div
                    class="actions__btn d-flex align-center uppercase color-white"
                    @click="isModalActive = true"
                    style="cursor: pointer;"
                ><span>Contact us</span></div>
                <div class="menu-btn d-flex" @click="isMenuActive = true" style="cursor: pointer">
                    <div class="menu-btn__item" v-for="i in 4"></div>
                </div>
            </div>
        </div>
    </header>
    <transition name="fade2">
        <UiModal :key="isModalActive" v-if="isModalActive && !isModalActiveIn2" v-model="isModalActive" @modal="(val) => isModalActiveIn2 = true"/>
    </transition>
    <transition>
        <BaseMenu v-show="isMenuActive == true" v-model="isMenuActive" />
    </transition>
</template>

<script setup>
import {ref, onMounted, onUnmounted} from "vue";
import UiIcon from "@/components/Ui/UiIcon.vue";
import BaseMenu from "@/components/Base/BaseMenu.vue";
import UiModal from "@/components/Ui/UiModal.vue";

const isMenuActive = ref(false);
const isModalActiveIn2 = ref(false);
const isModalActive = ref(false);
const isFixed = ref(false);
const headerHeight = 0;

const handleScroll = () => {
    const scrollTop = window.scrollY || document.documentElement.scrollTop;
    isFixed.value = scrollTop > headerHeight;
}

onMounted(() => {
    window.addEventListener('scroll', handleScroll);
})
onUnmounted(() => {
    window.removeEventListener('scroll', handleScroll);
})
</script>

<style lang="scss" scoped>
// .fade-enter, .fade-leave-to {
//   opacity: 0;
// }
.fade2-leave-active {
    transition: opacity .2s;
}
.fade2-enter, .fade2-leave-to {
  opacity: 0;
}
header {
    padding: 18px 0 16px;
    z-index: 10;
    position: relative;
    background: initial;
    transition: background .4s ease;
    & .logo {
        img + img {
            margin-left: 8px;
        }
    }
    &.fixed {
        border-radius: 0px 0px 20px 20px;
        background: radial-gradient(1049.73% 532.45% at 13.89% -37.5%, #4F71C4 0%, #36BAC1 100%), #4F71C4;
        position: fixed;
        z-index: 3;
        top: 0;
        left: 0;
        right: 0;
        transition: background .4s ease;
    }
}
.menu-btn {
    flex-wrap: wrap;
    width: 31px;
    height: 31px;
    gap: 6px;
    transition: all .2s ease;
    &:hover {
        opacity: 0.7;
    }
    &__item {
        width: 12.3px;
        height: 12.3px;
        border-radius: 2.5px;
        background: $white;
    }
}
.actions {
    gap: 35px;
    &__btn {
        padding: 8px 16px;
        border-radius: 50px;
        background: rgba(255, 255, 255, 0.22);
        transition: all .2s ease;
        span {
            font-size: 20px;
            font-weight: 600;
        }
        &:hover {
            background: rgba(255, 255, 255, 0.40);
            opacity: 0.7;
        }
    }
}

@media screen and (max-width: 600px) {
    header {
        padding: 23px 0 22px;
    }
    .logo {
        flex-direction: column;
        img + img {
            margin: 7px 0 0 0 !important;
        }
    }
    .actions {
        &__btn {
            display: none;
        }
    }
}

@media screen and (min-width: 601px) and (max-width: 1024px) {
    .actions {
        &__btn span {
            font-size: 18px;
        }
    }
}
</style>